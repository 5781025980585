.PhotoCardContainer {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  background-color: white;
  border-radius: 20px;
  margin: 20px;
  margin-top: 40px;
  max-width: 1000px;
  width: 80%;
}

.Description {
  padding: 20px 20px 20px 0px;
  flex: 1.5;
  min-width: 200px;
}

.Description > p {
  padding-left: 16px;
  margin: 0px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.6);
}

.Description > h1 {
  margin-bottom: 16px;
  margin: 0px;
  padding-left: 16px;
}

.PhotoCard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Photo {
  box-shadow: rgba(0, 0, 0, 0.9) 0px 3px 12px;
  background-size: cover;
  border-radius: 20px;
  flex: 1;
  position: relative;
  left: -20px;
  top: -20px;
  min-width: 100px;
}

@media (max-width: 700px) {
  .PhotoCard {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .Photo {
    min-height: 500px;
  }
  .Description {
    padding: 20px 20px 20px 10px;
    flex: 1.5;
    min-width: 200px;
  }

  .Description > p {
    padding-left: 16px;
    margin: 0px;
    text-align: justify;
    color: rgba(0, 0, 0, 0.6);
  }

  .Description > h1 {
    margin-bottom: 16px;
    margin: 0px;
    padding-left: 16px;
  }
}

.Empty {
  width: 100px;
  height: 200px;
}

.SubHeaderSmall {
  color: rgba(0, 0, 0, 0.6);
  padding: 6px;
}

.CustomersLogos {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  width: 100%;
  text-align: center;
  padding: 12px;
  margin-top: 6px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
}

.CustomerLogo > img {
  height: 100%;
}

.CustomerLogo {
  height: 40px;
  margin-left: 6px;
  margin-right: 6px;
}
